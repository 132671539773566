<template>
  <div>
    <div class="container">
      <h1 class="page-title">Modules</h1>
    </div>
    <div class="course-part course-part-one">
      <div class="container">
        <div class="course-part--header">
          <img src="@/assets/icon-lecturer-grey.svg" alt="" />
          <div>
            <div class="course-part--label">from September to February</div>
            <h2 class="course-part--title">Part one</h2>
          </div>
        </div>
      </div>
      <course-list :courses="coursesPart1" />
    </div>
    <div class="course-part course-part-two">
      <div class="container">
        <div class="course-part--header">
          <img src="@/assets/icon-lecturer-grey.svg" alt="" />
          <div>
            <div class="course-part--label">from March to June</div>
            <h2 class="course-part--title">Part two</h2>
          </div>
        </div>
      </div>
      <course-list :courses="coursesPart2" :isDark="true" />
    </div>
    <div class="past-courses" v-if="pastCourses.length > 0">
      <div class="container">
        <h2>Other courses from Past Editions</h2>
        <ul class="past-courses--list">
          <li class="bullet-list-deco green-deco" v-for="(course, index) of pastCourses" :key="index">{{course.attributes.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CourseList from "@/components/CourseList.vue";

export default {
  data() {
    return {
      courses: [],

    };
  },
  components: {
    CourseList,
  },
  computed: {
    coursesPart1() {
      return this.courses.filter((course) => {
        return !course.isPast && course.attributes.field_part === "1";
      }).sort(this.sortCourses);
    },
    coursesPart2() {
      return this.courses.filter((course) => {
        return !course.isPast && course.attributes.field_part === "2";
      }).sort(this.sortCourses);
    },
    pastCourses(){
      return this.courses.filter((course) => {
        return course.isPast ;
      }).sort(this.sortCourses);
    },
    currentAcademicYear(){
      return this.$store.state.currentAcademicYear
    }
  },
  methods: {
    sortCourses(a,b){
        if (a.attributes.title < b.attributes.title) { return -1; }
        if (a.attributes.title > b.attributes.title) { return 1; }
        return 0;
    },
    fetchCourses() {
      fetch(
        `${process.env.VUE_APP_ENDPOINT}/jsonapi/node/course?include=field_course_lecturer,field_academic_year`
      )
        .then((res) => res.json())
        .then((json) => {
          const included = json.included;
          this.courses = json.data.map((course) => {

            // aggiungo i lecturer
            if (course.relationships.field_course_lecturer.data) {
              var lecturersIds = course.relationships.field_course_lecturer.data.map((l) => l.id)
              course.lecturers = included.filter((item) => {
                return lecturersIds.includes(item.id);
              });
            }

            course.isPast = true;

            // aggiungo gli academic_year
            if (course.relationships.field_academic_year.data) {
              var academicYearsIds = course.relationships.field_academic_year.data.map((l) => l.id)
              course.academicYears = included
                .filter((item) => {
                  if (item.type === "taxonomy_term--academic_year"){
                    return academicYearsIds.includes(item.id);
                  }
                });

              course.academicYears.forEach(year=>{

                if(year.attributes.name === this.currentAcademicYear){
                  course.isPast = false;
                }
              })
            }

            //console.log('course',course);
            return course;
          });
        });
    },
  },
  mounted() {
    this.fetchCourses();
  },
};
</script>
<style lang="scss" scoped>


.course-part--label {
  text-transform: uppercase;
  font-size: 16px;
  color: #1dc07e;
  line-height: 1;
}
.course-part--title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 1;
  margin: 0;
}
.course-part--header {
  display: flex;
  align-items: center;
  margin-top: 55px;
  margin-bottom: 55px;
  img {
    margin-right: 16px;
  }
}
.course-part-two {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  background: #293446;
  .course-part--title {
    color: #fff;
  }
}
.past-courses {
  background: #293446;
  color: #fff;
  padding: 50px 0;
  h2{
    font-weight: 200;
    text-transform: uppercase;
  }
}
.past-courses--list{
  padding: 30px 0 0;
  li{
    display: block;
    margin:0;
    padding: 1rem 0rem 1rem 4rem;
  }
}

@media (max-width:576px) {
  .course-part--header{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
</style>