<template>
  <div :class="isDark ? 'dark' : ''">
    <div v-for="(course, index) of courses" :key="index" class="course-block">
      <div class="tile-bg"></div>
      <div class="container">
        <div class="course-header">
          <h2 class="course-title">
            <!-- <router-link :to="'/modules/' + course.id"></router-link> -->
            {{course.attributes.title}}
          </h2>
          <div class="course-lecturers">
            <img src="@/assets/icon-course-lecturer.svg" alt="" />
            <router-link
              :to="personLink(person)"
              v-for="(person, index) of course.lecturers"
              :key="index"
              >{{ personName(person) }}</router-link
            >
          </div>
        </div>
        <div
          class="course-description"
          v-html="course.attributes.field_shared_description.processed"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { slugify } from '@/libs/utils'
export default {
  props: {
    courses: Array,
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    personLink(person){
      let personSlug = slugify(this.personName(person))

      return `/person/${personSlug}-${person.attributes.drupal_internal__nid}`
    },
    personName(person) {
      return (
        person.attributes.title
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.dark h2 {
  color: #fff;
}
#app .dark .course-block .tile-bg,
#app .dark .course-block:nth-child(odd) .tile-bg {
    border-color: #ebebeb5c;
}
.course-title {
  a {
    color: #011b4a;
  }
  text-transform: uppercase;
}
.course-block {
  position: relative;
  margin: 40px 0 0;
  padding: 40px 0 0;
  border-top: 2px solid #ebebeb;
  .container{
    position: relative;
  }
  ::v-deep p {
    margin: 0;
  }
}
.course-lecturers {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
  a + a::before {
    content: "-";
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
  }
}
.dark {
  .title-big{
    border: 2px solid #ebebeb2c;
  }
}
@media (min-width: 992px) {
  .course-block {
    padding: 60px 70px;
    border: 0;
    margin: 55px 0;
    .container {
      display: flex;
    }
  }
  .course-header {
    width: 40%;
    margin-right: 15px;
    flex-shrink: 0;
  }
  .course-block:nth-child(odd) {
    .tile-bg {
      left: auto;
      right: 0;
      border-right: 0;
      border-left: 2px solid #ebebeb;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 200px;
      border-bottom-left-radius: 200px;
    }
  }
  .tile-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    height: 100%;
    border: 2px solid #ebebeb;
    border-left: 0;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;

  }
}
.dark {
  .course-title {
    a {
      color: #fff;
    }
  }
  .course-block {

    ::v-deep p {
      color: #fff;
    }
  }
}
@media (min-width: 1400px) {
  .tile-bg {
    width: 93%;
  }
}
</style>